import { React, useRef, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios';
import Swal from 'sweetalert2'


const JobApply = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [message, setMessage] = useState("");
    const [role, setRole] = useState("");
    const [portofolio, setPortofolio] = useState("");
    const recaptcha = useRef()
    const [fileObj,setFileObj] = useState("")
    function clearFields(event) {
        Array.from(event.target).forEach((e) => (e.value = ""));
    }
    const handleFileUpload = async (event) => {
        event.preventDefault()
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!')
        }
        else {
            // get the selected file from the input
            const file = event.target.files[0];
            // create a new FormData object and append the file to it
            const formData = new FormData();
            formData.append("file", file);
            // make a POST request to the File Upload API with the FormData object and Rapid API headers
            setLoading(true);
            try {
                await axios.post(process.env.REACT_APP_API_URL+"/upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                    .then((response) => {
                        // handle the response
                        // console.log(response);
                        let obj = {
                            filename:response.data.response.fileName,
                            path:process.env.REACT_APP_API_URL+"/doc/"+response.data.response.fileName
                        }
                        setFileObj(obj)
                        Swal.fire({
                            title: "Successfully Uploaded",
                            text: "",
                            icon: "success"
                          });
                    })

            }
            catch (error) {
                // handle errors
                console.log(error);
            }
            finally {
                setLoading(false); // Hide loader after API response
            }
        }
    };
    async function submitForm(event) {
        event.preventDefault()
        console.log(fileObj)
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please verify the CAPTCHA!',
                icon: 'info'
              })
        } else {
            // make form submission
            let dataObj = {
                name: name,
                email: email,
                to:"careers@procurextech.com",
                // to:"sudeeshannem@gmail.com",
                mobile: mobile,
                message:message,
                role:role,
                portofolio:portofolio,
                attachments:[fileObj]
            }
            setLoading(true);
            try {
                
                let res = await axios.post(process.env.REACT_APP_API_URL+"/mail", dataObj);
                if (res.status === 200) {
                    clearFields(event)
                    setFileObj('')
                    Swal.fire({
                        title: "Thank You For Your Intrest!",
                        text: "",
                        icon: "success"
                      });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Error in API Submission!',
                        icon: 'error'
                      })
                }
            } catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false); // Hide loader after API response
            }
        }
    }
    return (
        <>
            <div>


                {/* <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div> */}
                {loading && <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div>}
                <div className="job-listing-page pd-top-190">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10">
                                <div className="section-title text-center">
                                    <h2 className="title" style={{ color: "#0f172a" }}>Apply Now</h2>
                                    <p>Please upload your resume and fill in the fields below to apply for your desired position.</p>
                                </div>
                                <div className="job-apply-area">
                                    <form id="myForm" className="riyaqas-form-wrap" onSubmit={submitForm} >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="single-input-wrap">
                                                    <input type="text" name="first_name" placeholder="Name" className="single-input" onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="single-input-wrap">
                                                    <input type="text" name="email" placeholder="E-mail" className="single-input" onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="single-input-wrap">
                                                    <input type="text" placeholder="Mobile" className="single-input" onChange={(e) => setMobile(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="single-input-wrap">
                                                    <input type="text" placeholder="Applying for the role of" className="single-input" onChange={(e) => setRole(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="single-input-wrap">
                                                    <textarea className="single-input" placeholder="Portfolio Link" cols="20" onChange={(e) => setPortofolio(e.target.value)}></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="single-input-wrap">
                                                    <textarea className="single-input" placeholder="Write Your Message" name="text" cols="20" onChange={(e) => setMessage(e.target.value)}></textarea>
                                                </div>
                                            </div>
                                            <div className="col-12" >
                                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                            </div>
                                            <div className="col-12" style={{ "margin-top": "10px" }}>
                                                <div className="custom-file riyaqas-file-input-wrap">
                                                    <input type="file" className="riyaqas-file-input" id="sb-file-input" onChange={handleFileUpload} />
                                                    <label className="custom-file-label" for="sb-file-input" style={{ "background-color": "#2563eb", "border-color": "#2563eb" }}>Upload Your Resume</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input className="btn rounded-full" type="submit" value="Submit" style={{ color: "white", "background-color": "#2563eb", "border-color": "#2563eb" }} />
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up"></i></span>
                </div>

            </div>
        </>
    )
}

export default JobApply;