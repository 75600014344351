import React from "react";
import { Link } from "react-router-dom";


const JobListing = () => {
    return (
        <>
            <div>
                {/* <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div> */}
                <div className="breadcrumb-area" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner">
                                    <h1 className="page-title" style={{ color: "#0f172a" }}>JOIN <span style={{color:"#2563eb"}}>US</span></h1>
                                    <ul className="page-list">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Career</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="job-listing-page  pd-top-112">
                    <div className="container">
                        <div className="job-position-area">
                            <div className="row justify-content-center">
                                <div className="col-xl-8" style={{"text-align":"center"}}>
                                    <div className="section-title style-four">
                                        <h2 className="title" style={{ color: "#0f172a" }}>Open <span>Job</span> Positions</h2>
                                    </div>
                                    <div className="single-job-list media">
                                        <img src="img/prc7.png" alt="icon" />
                                        <div className="media-body">
                                            <h6>React Native - Mobile App Developer </h6>
                                            <span>3-5 years of professional experience</span>
                                        </div>
                                        <Link to={"/jobDetails/ReactNative"}><button className="job-apply-btn align-self-center" >Apply Now</button></Link>
                                        
                                    </div>
                                    <div className="single-job-list media">
                                        <img src="img/prc7.png" alt="icon" />
                                        <div className="media-body">
                                            <h6>QA Testing </h6>
                                            <span>3-5 years of QA(Manual & Automation) experience</span>
                                        </div>
                                        <Link to={"/jobDetails/QATest"}><button className="job-apply-btn align-self-center" >Apply Now</button></Link>
                                        
                                    </div>

                                    <div className="single-job-list media">
                                        <img src="img/prc7.png" alt="icon" />
                                        <div className="media-body">
                                            <h6>Node.js developer </h6>
                                            <span>3-5 years of experience in Node.js</span>
                                        </div>
                                        <Link to={"/jobDetails/NodeJS"}><button className="job-apply-btn align-self-center" >Apply Now</button></Link>
                                        
                                    </div>

                                    <div className="single-job-list media">
                                        <img src="img/prc7.png" alt="icon" />
                                        <div className="media-body">
                                            <h6>Nest.js developer </h6>
                                            <span>3-5 years of experience in Nest.js</span>
                                        </div>
                                        <Link to={"/jobDetails/NestJS"}><button className="job-apply-btn align-self-center" >Apply Now</button></Link>
                                        
                                    </div>

                                    <div className="single-job-list media">
                                        <img src="img/prc7.png" alt="icon" />
                                        <div className="media-body">
                                            <h6>UI Developer </h6>
                                            <span>3-5 years of proven experience in UI development, specifically with React JS</span>
                                        </div>
                                        <Link to={"/jobDetails/UI"}><button className="job-apply-btn align-self-center" >Apply Now</button></Link>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up"></i></span>
                </div>


            </div>
        </>
    )
}

export default JobListing;