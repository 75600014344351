import React from "react";
import { Link } from "react-router-dom";

const Footer = () =>{
    return (
        <>
            <div style={{"margin-top":"-100px"}}>
            <footer className="footer-area footer-area-2 style-two mg-top-120">
                    <div className="container">
                        <div className="footer-widget-area">
                            <div className="row" style={{"justify-content": "center"}}>
                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-widget widget">
                                        <div className="about_us_widget">
                                            <Link to="/" className="footer-logo">
                                                <img src="img/prc8.png" style={{width: "210px"}} alt="footer logo" />
                                            </Link>
                                            <p>To redefine the construction and manufacturing sectors, we provide pioneering solutions that streamline planning, procurement, and inventory management processes.</p>
                                            <ul className="social-icon">
                                                <li>
                                                    <a className="twitter" href="#" target="_blank"><i className="fa fa-twitter  "></i></a>
                                                </li>
                                                <li>
                                                    <a className="linkedin" href="https://www.linkedin.com/company/procurex-technologies/about/" target="_blank"><i className="fa fa-linkedin"></i></a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6">
                                    <div className="footer-widget widget ">
                                        <h4 className="widget-title">Contact</h4>
                                        <div className="contact_info_list">
                                            <p className="contact-content">Ira Reality, Beside Anvaya convenction Rd,Financial District,Vattinagulapally,Hyderabad,Telengana 500032</p>
                                            <p><span>E-mail:</span> info@procurextech.com</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-inner">
                        <div className="copyright-text">
                            Copyright &copy; 2024 ProcureX - All Rights Reserved.
                    </div>
                    </div>

                </footer >
            </div>
        
        </>
    )
}

export default Footer;