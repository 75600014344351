import {React  } from "react";
import { Link  } from "react-router-dom";


const ProcureToPay = () => {
    return (
        <>
            <div>

                {/* <div className="preloader" id="preloader">
                <div className="preloader-inner">
                    <div className="spinner">
                        <div className="dot1"></div>
                        <div className="dot2"></div>
                    </div>
                </div>
            </div> */}
                <div>
                  <br></br>  
                  <br></br>
                  <br></br>  
                  <br></br>
                  <br></br>  
                  <br></br>
                </div>
                <div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-10">
                                <div className="section-title text-center margin-bottom-90" >
                                    <h2 className="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s" style={{ color: "#2563eb" }}>Procure to pay <span style={{ color: "#0f172a" }}>Modules</span>
                                    </h2>
                                    <div className="btn-wrapper padding-top-20">
                                        <Link to="/" className="btn btn-radius justify-center items-center s-animate-3"
                                            style={{ "background-color": "#2563eb", "border-color": "#2563eb", "border-radius": "35px", "font-size": "13px", width: "140px", height: "30px" }}>
                                            <p style={{ "margin-top": "-1px", color: "white", "font-family": "Inter,Arial,Helvetica,sans-serif", "font-size": "13px", "font-weight": "bold" }}>Back to home</p>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row custom-gutters-16">
                            <div className="col-xl-3 col-md-6 col-sm-6 justify-content-center">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s" style={{ "text-align": "center" }}>
                                    <img className="center" src="img/Dashboard/purchasemgmt.svg" alt="service" />
                                    <h6 style={{ "white-space": "nowrap" }}><a href="#">Purchase Requisition & Order<br />Management</a></h6>
                                    <p>Streamline purchase requests, budget approvals, and procurement processes seamlessly.</p>
                                    <div className="read-more">

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s" style={{ "text-align": "center" }}>
                                    <img className="center" src="img/Dashboard/BudgetApproval.svg" alt="service" />
                                    <h6><a href="#">Budget Creation & Approvals</a></h6>
                                    <p>Create and manage budgets with ease,ensuring compliance and cost efficiency.</p>
                                    <div className="read-more" style={{ "margin-top": "50px" }}>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s" style={{ "text-align": "center" }}>
                                    <img className="center" src="img/Dashboard/SupplierQuote.svg" alt="service" />
                                    <h6 ><a href="#">Supplier Quotaion Analysis</a></h6>
                                    <p>Evaluate supplier quotations with detailed analysis tools, enabling informed decision-making.
                                    </p>
                                    <div className="read-more" style={{ "margin-top": "50px" }}>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s" style={{ "text-align": "center" }}>
                                    <img className="center" src="img/Dashboard/SupplierFeedback.svg" alt="service" />
                                    <h6 ><a href="#">Negotiation & Feedback</a></h6>
                                    <p>Facilitate efficient negotiations and provide constructive feedback to suppliers, fostering
                                        stronger partnerships.</p>
                                    <div className="read-more" style={{ "margin-top": "50px" }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row custom-gutters-16">
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s" style={{ "text-align": "center" }}>
                                    <img className="center" src="img/Dashboard/Ratecontract.svg" alt="service" />
                                    <h6 className="center" style={{ "margin-left": "75px" }}><a href="#">Rate Contracts</a></h6>
                                    <p>Establish rate contracts with suppliers to lock in prices for specific periods, ensuring
                                        stability in procurement costs.</p>
                                    <div className="read-more">

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s" style={{ "text-align": "center" }}>
                                    <img className="center" src="img/Dashboard/AuctionModule.svg" alt="service" />
                                    <h6 ><a href="#">Auction Module</a></h6>
                                    <p>Conduct both reverse and forward auctions to optimize procurement costs and drive value.</p>
                                    <div className="read-more">

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s" style={{ "text-align": "center"}}>
                                    <img className="center" src="img/Dashboard/purchasemgmt.svg" alt="service" />
                                    <h6 style={{ "white-space": "nowrap" }}><a href="#">Purchase Order Management</a></h6>
                                    <p>Simplify purchase order creation and tracking for efficient procurement operations.</p>
                                    <div className="read-more" style={{ "margin-top": "25px" }}>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s" style={{ "text-align": "center" }}>
                                    <img className="center" src="img/Dashboard/Goodsissue.svg" alt="service" />
                                    <h6><a href="#">Goods Receipt & Issue</a></h6>
                                    <p> Streamline goods receipt notes and issue processes, enhancing inventory management.</p>
                                    <div className="read-more" style={{ "margin-top": "25px" }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row custom-gutters-16">
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s" style={{ "text-align": "center" }}>
                                    <img className="center" src="img/Dashboard/sitinvtry.svg" alt="service" />
                                    <h6><a href="#">Inventory Management</a></h6>
                                    <p>Track and manage inventory levels effectively to prevent stockouts and optimize inventory
                                        turnover.</p>
                                    <div className="read-more">

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s" style={{ "text-align": "center" }}>
                                    <img className="center" src="img/Dashboard/Payments.svg" alt="service" />
                                    <h6><a href="#">Payments Module</a></h6>
                                    <p>Facilitate seamless payments to suppliers, ensuring timely transactions and maintaining
                                        strong supplier relationships.</p>
                                    <div className="read-more" style={{ "margin-top": "25px" }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up"></i></span>
                </div>

            </div>
        </>
    )
}

export default ProcureToPay;