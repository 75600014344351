import {React,useRef,useState} from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Swal from 'sweetalert2'

const Wishlist = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [message, setMessage] = useState("");
    const [designation, setDesignation] = useState("");
    const [category, setCategory] = useState("");
    const [company, setCompany] = useState("");
    const [subject, setSubject] = useState("");

    const recaptcha = useRef()
    function clearFields(event) {
        Array.from(event.target).forEach((e) => (e.value = ""));
      }
    async function submitForm(event) {
        event.preventDefault()
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please verify the CAPTCHA!',
                icon: 'info'
              })
        } else {
            // make form submission
            let dataObj = {
                name: name,
                email: email,
                to:"info@procurextech.com",
                // to:"sudeeshannem@gmail.com",
                mobile: mobile,
                message:message,
                designation:designation,
                category:category,
                company:company,
                subject:subject,
                attachments:[]
              }
              console.log(dataObj)
              setLoading(true);
            try {
                let res = await axios.post(process.env.REACT_APP_API_URL+"/mail", dataObj);
                if (res.status === 200) {
                    clearFields(event);
                    Swal.fire({
                        title: "Thank You For Your Intrest!",
                        text: "",
                        icon: "success"
                      });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Error in API Submission!',
                        icon: 'error'
                      })
                }
              } catch (err) {
                console.log(err);
              }
              finally {
                setLoading(false); // Hide loader after API response
            }
        }
    }
    return (
        <>
            
            <div>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-nice-select/1.1.0/js/jquery.nice-select.min.js"></script>
                {/* <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div> */}
                {loading &&  <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div>}
                <div className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner">
                                    <h1 className="page-title" style={{ color: "#0f172a" }}>JOIN OUR <span style={{ color: "#2563eb" }}>WISHLIST</span></h1>
                                    <ul className="page-list">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Contact</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-form-area pd-top-112">
                    <div className="container">
                        {/* <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                <div className="section-title text-center w-100">
                                    <h2 className="title" style={{ color: "#0f172a" }}>Send your <span>enquiry</span></h2>
                                    <p>We are here to help and answer any questions you might have.We look forward to hearing from you.</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-5">
                                <img src="img/others/21.png" alt="blog" />
                            </div>
                            <div className="col-lg-7 offset-xl-1">
                                <form className="riyaqas-form-wrap mt-5 mt-lg-0" onSubmit={submitForm}>
                                    <div className="row custom-gutters-16">
                                        <div className="col-md-6">
                                            <div className="single-input-wrap">
                                                <input type="text" className="single-input" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-input-wrap">
                                                <input type="text" className="single-input" placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-input-wrap">
                                                <input type="text" className="single-input" placeholder="Company" onChange={(e) => setCompany(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-input-wrap">
                                                <input type="text" className="single-input" placeholder="Designation" onChange={(e) => setDesignation(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-input-wrap">
                                                <input type="text" className="single-input" placeholder="Mobile" onChange={(e) => setMobile(e.target.value)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="single-input-wrap">
                                                <select class="select single-select" onChange={(e) => setCategory(e.target.value)}>
                                                    <option value="" disabled selected>Select Category</option>
                                                    <option value="Buyer" >Buyer</option>
                                                    <option value="Supplier">Supplier</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-input-wrap">
                                                <input type="text" className="single-input" placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-input-wrap">
                                                <textarea className="single-input textarea" cols="20"placeholder="Message" onChange={(e) => setMessage(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                        </div>
                                        <div className="col-12" style={{ "margin-top": "10px" }}>
                                            <button className="btn  mt-0" style={{ "background-color": "#2563eb", "border-color": "#2563eb" }}><p style={{ color: "white", "margin-top": "10px" }}>Send</p></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up"></i></span>
                </div>
            </div >
        </>
    )
}

export default Wishlist;