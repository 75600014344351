// import logo from './logo.svg'
// import './App.css'
import Contact from './pages/Contact'
import Home from './pages/Home'
import { Routes, Route } from 'react-router-dom'
import JobApply from './pages/JobApply'
import JobDetails from './pages/JobDetails'
import JobListing from './pages/JobListing'
import ProcureToPay from './pages/ProcureToPay'
import Navbar from './pages/Navbar'
import Footer from './pages/Footer'
import Wishlist from './pages/Wishlist'
import ScrollToTop from './components/ScrollToTop'
function App() {
    return (
        <div className="App">
            <ScrollToTop/>
            <Navbar/>
            <Routes>
                <Route path='/' element={<Home />}></Route>
                <Route path='/contact' element={<Contact />}></Route>
                <Route path='/jobApply' element={<JobApply />}></Route>
                <Route path='/jobDetails/:id' element={<JobDetails />}></Route>
                <Route path='/jobListing' element={<JobListing />}></Route>
                <Route path='/procureToPay' element={<ProcureToPay />}></Route>
                <Route path='/wishList' element={<Wishlist />}></Route>
            </Routes>
            <Footer />
        </div>
    )
}

export default App
