import React from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
    const { pathname } = useLocation();
    return (
        <>
            <div>
                <nav className="navbar navbar-area navbar-expand-lg nav-style-01">
                    <div className="container nav-container">
                        <div class="responsive-mobile-menu">
                            <div className="logo-wrapper mobile-logo">
                                <Link to="/" class="logo">
                                    <img src="img/prc8.png" alt="logo" />
                                </Link>
                            </div>
                            <div className="navbar-toggler">
                            <Link to="wishList" className="btn btn-radius btn-info rounded-full s-animate-3"
                                    style={{ "background-color": "#2563eb", "border-color": "#2563eb", "border-radius": "35px", "font-size": "13px", width: "140px", height: "30px" }}>
                                    <p style={{ "color": "white", "font-weight": "bold"}}>Join the wishlist</p>
                                </Link>
                            </div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#Riyaqas_main_menu"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggle-icon">
                                    <span class="line"></span>
                                    <span class="line"></span>
                                    <span class="line"></span>
                                </span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
                            <div className="logo-wrapper desktop-logo">
                                <Link to="/" className="logo">
                                    <img src="img/prc8.png" style={{ width: "183px" }} alt="logo" />
                                </Link>
                            </div>
                            <ul className="navbar-nav">
                                <li>
                                    <Link to="/" style={{ "font-family": "Inter,Arial,Helvetica,sans-serif", "font-size": "13px", "font-weight": "bold", "display": "flex", color: "#0f172a" }}>Home</Link>
                                </li>
                                <li>
                                    <a href="/#modules" style={{ "font-family": "Inter,Arial,Helvetica,sans-serif", "font-size": "13px", "font-weight": "bold", display: "flex", color: "#0f172a" }}>Product</a>
                                </li>
                                <li>
                                    <Link to="jobListing" style={{ "font-family": "Inter,Arial,Helvetica,sans-serif", "font-size": "13px", "font-weight": "bold", display: "flex", color: "#0f172a" }}>Career</Link>
                                </li>
                                <li>
                                    <Link to="contact" style={{ "font-family": "Inter,Arial,Helvetica,sans-serif", "font-size": "13px", "font-weight": "bold", display: "flex", color: "#0f172a" }}>Contact</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="nav-right-content">
                            <ul>
                                <li>
                                    <div className="btn-wrapper padding-top-20">
                                        <Link to="wishList" className="btn btn-radius btn-info rounded-full s-animate-3"
                                            style={{ "background-color": "#2563eb", "border-color": "#2563eb", "border-radius": "35px", "font-size": "13px", width: "140px", height: "30px" }}>
                                            <p style={{ "color": "white", "font-weight": "bold", "margin-top": "-1px" }}>Join the wishlist</p>
                                        </Link>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar;