import React from "react";
import { Link, useParams } from "react-router-dom";


const JobDetails = () => {
    const { id } = useParams();

    const divElements = [
        {
            id: 'ReactNative', content: <div className="row justify-content-center" id="ReactNative">
                <div className="col-xl-6 col-lg-8 offset-xl-1">
                    <div className="section-title">
                        <h2 className="title">Job Details</h2>
                    </div>
                    <h6 className="title">React Native Developer</h6>
                    <span>Procurex Technologies</span>
                    <h6 className="sub-title">Vacancy</h6>
                    <span>01</span>
                    <h6 className="sub-title">Job Responsibilities</h6>
                    <ul>
                        <li>Develop and maintain mobile applications using React Native</li>
                        <li>Collaborate with cross-functional teams to define, design, and ship new features</li>
                        <li>Ensure robustness, performance, and responsiveness of applications</li>
                        <li>Integrate third-party API services and oversee the entire application lifecycle (concept, design, test, release, and support)</li>
                        <li>Implement Firebase push notifications and ensure they function optimally across different devices and platforms</li>
                        <li>Create interactive and scalable charts and data visualizations within mobile applications</li>
                        <li>Maintain code quality, organization, and automatization</li>
                        <li>Troubleshoot and debug to optimize performance</li>
                    </ul>
                    <h6 className="sub-title">Experience Requirements</h6>
                    <p>3 to 5 year(s) of professional experience in mobile application development</p>
                    <p>Proficient with React Native and its core principles</p>
                    <p>Hands-on experience in developing applications for both iOS and Android platforms</p>
                    <p>Strong experience with Firebase, specifically implementing push notifications</p>
                    <p>Experience with data visualization libraries and tools for implementing charts</p>
                    <p>Knowledge of RESTful APIs to connect React Native applications to back-end services</p>
                    <p>Strong understanding of the full mobile development life cycle</p>
                    <h6 className="sub-title">Job Location</h6>
                    <p>Hyderabad</p>
                    <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                </div>
            </div>
        },
        {
            id: 'QATest', content: <div className="row justify-content-center" id="QATest">
                <div className="col-xl-6 col-lg-8 offset-xl-1">
                    <div className="section-title">
                        <h2 className="title">Job Details</h2>
                    </div>
                    <h6 className="title">QA Testing</h6>
                    <span>Procurex Technologies</span>
                    <h6 className="sub-title">Vacancy</h6>
                    <span>01</span>
                    <h6 className="sub-title">Job Responsibilities</h6>
                    <ul>
                        <li>Review and analyse Functional requirements</li>
                        <li>Create and execute test plans and test cases</li>
                        <li>Identify, document, and report Application defects</li>
                        <li>Collaborate with developers to resolve issues</li>
                        <li>Ensure the final product meets the established quality standards</li>
                    </ul>
                    <h6 className="sub-title">Experience Requirements</h6>
                    <p>3 to 5 year(s) of QA(Manual & Automation) experience</p>
                    <p>Strong analytical and problem-solving skills</p>
                    <p>Knowledge of software development methodologies and processes</p>
                    <p>Familiarity with various testing tools and techniques</p>
                    <p>Excellent communication and collaboration skills</p>
                    <p>Worked on any supply chain related products (For Min. 2 Years) will be an added advantage</p>
                    <p>Degree in B.tech(Any Branch), BBA, MBA or any relevant Fields</p>
                    <h6 className="sub-title">Job Location</h6>
                    <p>Hyderabad</p>
                    
                    <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                </div>
                
            </div>
        },
        {
            id: 'NodeJS', content: <div className="row justify-content-center" id="NodeJS">
                <div className="col-xl-6 col-lg-8 offset-xl-1">
                    <div className="section-title">
                        <h2 className="title">Job Details</h2>
                    </div>
                    <h6 className="title">NodeJS Developer</h6>
                    <span>Procurex Technologies</span>
                    <h6 className="sub-title">Vacancy</h6>
                    <span>01</span>
                    <h6 className="sub-title">Job Responsibilities</h6>
                    <ul>
                        <li>Design, develop, and maintain server-side applications using NodeJS</li>
                        <li>Collaborate with team members to plan, design, and implement new features and functionality</li>
                        <li>Ensure the quality and performance of code through testing and debugging</li>
                        <li>Stay up-to-date with the latest trends and best practices in web development</li>
                        <li>Converting video, audio, written, and graphic components into compatible formats for the web application</li>
                        <li>Using user feedback to identify and correct problems with a clients software applications</li>
                        <li>Maintain code quality, organization, and automatization</li>
                        <li>Troubleshoot and debug to optimize performance</li>
                    </ul>
                    <h6 className="sub-title">Experience Requirements</h6>
                    <p>3 to 5 year(s) of experience in NodeJS development</p>
                    <p>A strong understanding of TypeORM, PostgreSQL, MySQL, and Redis Cache</p>
                    <p>Experience with Kafka and event-driven architecture</p>
                    <p>Familiarity with CI/CD tools and practices</p>
                    <p>Proficiency in Azure Cloud services, with a solid understanding of cloud-based application deployment and management</p>
                    <p>Knowledge of code versioning tools such as Git</p>
                    <p>Ability to thrive in a fast-paced environment, managing multiple projects with tight deadlines</p>
                    <p>Strong problem-solving skills and attention to detail</p>
                    <p>Excellent communication and teamwork skills</p>
                    <p>A Bachelor’s degree in Computer Science, Engineering, or a related field</p>
                    <p>Hyderabad</p>
                    <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                </div>
            </div>
        },
        {
            id: 'NestJS', content: <div className="row justify-content-center" id="NestJS">
                <div className="col-xl-6 col-lg-8 offset-xl-1">
                    <div className="section-title">
                        <h2 className="title">Job Details</h2>
                    </div>
                    <h6 className="title">NestJS Developer</h6>
                    <span>Procurex Technologies</span>
                    <h6 className="sub-title">Vacancy</h6>
                    <span>01</span>
                    <h6 className="sub-title">Job Responsibilities</h6>
                    <ul>
                        <li>Design, develop, and maintain server-side applications using NestJS</li>
                        <li>Collaborate with team members to plan, design, and implement new features and functionality</li>
                        <li>Ensure the quality and performance of code through testing and debugging</li>
                        <li>Stay up-to-date with the latest trends and best practices in web development</li>
                        <li>Converting video, audio, written, and graphic components into compatible formats for the web application</li>
                        <li>Using user feedback to identify and correct problems with a clients software applications</li>
                        <li>Maintain code quality, organization, and automatization</li>
                        <li>Troubleshoot and debug to optimize performance</li>
                    </ul>
                    <h6 className="sub-title">Experience Requirements</h6>
                    <p>3 to 5 year(s) of experience in NestJS development</p>
                    <p>A strong understanding of TypeORM, PostgreSQL, MySQL, and Redis Cache</p>
                    <p>Experience with Kafka and event-driven architecture</p>
                    <p>Familiarity with CI/CD tools and practices</p>
                    <p>Proficiency in Azure Cloud services, with a solid understanding of cloud-based application deployment and management</p>
                    <p>Knowledge of code versioning tools such as Git</p>
                    <p>Ability to thrive in a fast-paced environment, managing multiple projects with tight deadlines</p>
                    <p>Strong problem-solving skills and attention to detail</p>
                    <p>Excellent communication and teamwork skills</p>
                    <p>A Bachelor’s degree in Computer Science, Engineering, or a related field</p>

                    <h6 className="sub-title">Job Location</h6>
                    <p>Hyderabad</p>
                    <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                </div>
            </div>
        },
        {
            id: 'UI', content: <div className="row justify-content-center" id="UI">
                <div className="col-xl-6 col-lg-8 offset-xl-1">
                    <div className="section-title">
                        <h2 className="title">Job Details</h2>
                    </div>
                    <h6 className="title">UI Developer</h6>
                    <span>Procurex Technologies</span>
                    <h6 className="sub-title">Vacancy</h6>
                    <span>01</span>
                    <h6 className="sub-title">Job Responsibilities</h6>
                    <ul>
                        <li>Maintain and improve the website by developing new user-facing and mobile-based features</li>
                        <li>Work with your team to design and build software applications to function across multiple platforms</li>
                        <li>Build reusable code and libraries for future use</li>
                        <li>Validate the technical feasibility of UI/UX designs</li>
                        <li>Optimize application for maximum speed and scalability</li>
                        <li>Assure that all user input is validated before submitting to the back-end</li>
                        <li>Conduct thorough testing of user interfaces in multiple platforms to ensure all designs render correctly and systems function properly</li>
                        <li>Write functional requirement documents and guides</li>
                        <li>Collaborate with other team members and stakeholders</li>
                    </ul>
                    <h6 className="sub-title">Experience Requirements</h6>
                    <p>3 to 5 year(s) of proven experience in UI development, specifically with React JS</p>
                    <p>Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model</p>
                    <p>Deep understanding of React JS, Redux, Bootstrap 5, React Grid, and charts librariess</p>
                    <p>Experienced with Tailwind CSS, LESS, and SASS</p>
                    <p>Familiarity with modern front-end build pipelines and tools</p>
                    <p>Experience with common front-end development tools such as Babel, Webpack, NPM, etc</p>
                    <p>Ability to understand business requirements and translate them into technical requirements</p>
                    <p>A knack for benchmarking and optimization</p>
                    <p>Excellent problem-solving skills and attention to detail</p>
                    <p>Strong communication and teamwork abilities</p>
                    <p>Bachelor’s degree in Computer Science, Design, or a related field</p>

                    <h6 className="sub-title">Job Location</h6>
                    <p>Hyderabad</p>
                    <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                </div>
            </div>
        },
    ];

    // Find the div element with the matching ID
    const selectedDiv = divElements.find(div => div.id === id);
    return (
        <>
            <div>

                {/* <div className="preloader" id="preloader">
    <div className="preloader-inner">
        <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
        </div>
    </div>
</div> */}

                <div className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner">
                                    <h1 className="page-title" style={{ color: "#0f172a" }}>Job Description</h1>
                                    <ul className="page-list">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Career</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="job-details-area pd-top-112">
                    <div className="container">
                        {/* <div className="row justify-content-center" id="ReactNative">
                            <div className="col-xl-6 col-lg-8 offset-xl-1">
                                <div className="section-title">
                                    <h2 className="title">Job Details</h2>
                                </div>
                                <h6 className="title">Fashion Designer</h6>
                                <span>Bevilard international</span>
                                <h6 className="sub-title">Vacancy</h6>
                                <span>04</span>
                                <h6 className="sub-title">Job Responsibilities</h6>
                                <ul>
                                    <li>Find out fashion trends of International market and to work out of them</li>
                                    <li>Creation of creative fashion design that suits the company brand's requirement.</li>
                                    <li>Development of new accessories design for upcoming collection.</li>
                                    <li>Analyze development in fabrics, colors and shapes</li>
                                    <li>Conduct brainstorming, designing and producing concepts</li>
                                    <li>Develop design ideas through coordination with merchandising and client.</li>
                                    <li>Ensure developing of patterns & overseeing the production</li>
                                    <li>Able to work in illustrator or any other fashion design software to develop Woven tops & Bottom sketches for men, ladies, kids accessories details.</li>
                                    <li>Must be able to develop new collection for every season.</li>
                                    <li>Making Flat Sketch (Tops & Bottoms) in graphic software.</li>
                                    <li>Design new labeling and badge.</li>
                                    <li>Making sketch according to Buyer request.</li>
                                    <li>Making presentation file for customer meeting.</li>
                                    <li>Design & develop men's and women's traditional product line up based on seasons that will generate sales in local and global market</li>
                                    <li>Study & analyze the market & industry trend to have a reflection on product design & development to fulfill the customer needs</li>
                                    <li>Present innovative saleable design ideas to the creative director, MD and sales team Determine product fabric & fitting (select fabrics, embellishments, color, styles which will fit for each garmentor accessory within the budget)</li>
                                    <li>Understanding design from technical perspective, developing pattern & supervision of these including fitting, detailing & adaptions </li>
                                    <li>Create a project book and check sample</li>
                                    <li>Employment Status</li>
                                    <li>Full-time</li>
                                </ul>
                                <h6 className="sub-title">Educational Requirements</h6>
                                <p>Bachelor of Fine Arts (B.F.A)</p>
                                <p>Training/Trade Course: Shanto Mariam University of Creative Technology,BGMEA University of Fashion</p>
                                <p>Technology students wi,Skills Required: Graphics Design; Local and world populated Fashion house (Buyer)</p>
                                <h6 className="sub-title">Experience Requirements</h6>
                                <p>1 to 3 year(s)</p>
                                <p>The applicants should have experience in the following area(s):</p>
                                <p>Fashion Design</p>
                                <h6 className="sub-title">Additional Requirements</h6>
                                <p>Age 26 to 35 years</p>
                                <p>Both males and females are allowed to apply</p>
                                <h6 className="sub-title">Job Location</h6>
                                <p>Gulshan, Dhaka Division, Dhaka.</p>
                                <h6 className="sub-title">Salary</h6>
                                <p className="m-0">Negotiable</p>
                                <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                            </div>
                            <div className="col-xl-3 col-lg-4 offset-xl-1">
                                <div className="widget widget-job-details">
                                    <h3 className="widget-title">Job Details</h3>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/1.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Department</h6>
                                            <span>UI Designer</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/2.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Location</h6>
                                            <span>Bangladesh</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/3.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Job Type</h6>
                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/4.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Experience</h6>
                                            <span>2 years</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details mb-0">
                                        <img src="assets/img/icons/5.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Salary</h6>
                                            <span>$2000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center" id="QATest">
                            <div className="col-xl-6 col-lg-8 offset-xl-1">
                                <div className="section-title">
                                    <h2 className="title">Job Details</h2>
                                </div>
                                <h6 className="title">Fashion Designer</h6>
                                <span>Bevilard international</span>
                                <h6 className="sub-title">Vacancy</h6>
                                <span>04</span>
                                <h6 className="sub-title">Job Responsibilities</h6>
                                <ul>
                                    <li>Find out fashion trends of International market and to work out of them</li>
                                    <li>Creation of creative fashion design that suits the company brand's requirement.</li>
                                    <li>Development of new accessories design for upcoming collection.</li>
                                    <li>Analyze development in fabrics, colors and shapes</li>
                                    <li>Conduct brainstorming, designing and producing concepts</li>
                                    <li>Develop design ideas through coordination with merchandising and client.</li>
                                    <li>Ensure developing of patterns & overseeing the production</li>
                                    <li>Able to work in illustrator or any other fashion design software to develop Woven tops & Bottom sketches for men, ladies, kids accessories details.</li>
                                    <li>Must be able to develop new collection for every season.</li>
                                    <li>Making Flat Sketch (Tops & Bottoms) in graphic software.</li>
                                    <li>Design new labeling and badge.</li>
                                    <li>Making sketch according to Buyer request.</li>
                                    <li>Making presentation file for customer meeting.</li>
                                    <li>Design & develop men's and women's traditional product line up based on seasons that will generate sales in local and global market</li>
                                    <li>Study & analyze the market & industry trend to have a reflection on product design & development to fulfill the customer needs</li>
                                    <li>Present innovative saleable design ideas to the creative director, MD and sales team Determine product fabric & fitting (select fabrics, embellishments, color, styles which will fit for each garmentor accessory within the budget)</li>
                                    <li>Understanding design from technical perspective, developing pattern & supervision of these including fitting, detailing & adaptions </li>
                                    <li>Create a project book and check sample</li>
                                    <li>Employment Status</li>
                                    <li>Full-time</li>
                                </ul>
                                <h6 className="sub-title">Educational Requirements</h6>
                                <p>Bachelor of Fine Arts (B.F.A)</p>
                                <p>Training/Trade Course: Shanto Mariam University of Creative Technology,BGMEA University of Fashion</p>
                                <p>Technology students wi,Skills Required: Graphics Design; Local and world populated Fashion house (Buyer)</p>
                                <h6 className="sub-title">Experience Requirements</h6>
                                <p>1 to 3 year(s)</p>
                                <p>The applicants should have experience in the following area(s):</p>
                                <p>Fashion Design</p>
                                <h6 className="sub-title">Additional Requirements</h6>
                                <p>Age 26 to 35 years</p>
                                <p>Both males and females are allowed to apply</p>
                                <h6 className="sub-title">Job Location</h6>
                                <p>Gulshan, Dhaka Division, Dhaka.</p>
                                <h6 className="sub-title">Salary</h6>
                                <p className="m-0">Negotiable</p>
                                <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                            </div>
                            <div className="col-xl-3 col-lg-4 offset-xl-1">
                                <div className="widget widget-job-details">
                                    <h3 className="widget-title">Job Details</h3>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/1.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Department</h6>
                                            <span>UI Designer</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/2.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Location</h6>
                                            <span>Bangladesh</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/3.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Job Type</h6>
                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/4.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Experience</h6>
                                            <span>2 years</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details mb-0">
                                        <img src="assets/img/icons/5.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Salary</h6>
                                            <span>$2000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center" id="NodeJS">
                            <div className="col-xl-6 col-lg-8 offset-xl-1">
                                <div className="section-title">
                                    <h2 className="title">Job Details</h2>
                                </div>
                                <h6 className="title">Fashion Designer</h6>
                                <span>Bevilard international</span>
                                <h6 className="sub-title">Vacancy</h6>
                                <span>04</span>
                                <h6 className="sub-title">Job Responsibilities</h6>
                                <ul>
                                    <li>Find out fashion trends of International market and to work out of them</li>
                                    <li>Creation of creative fashion design that suits the company brand's requirement.</li>
                                    <li>Development of new accessories design for upcoming collection.</li>
                                    <li>Analyze development in fabrics, colors and shapes</li>
                                    <li>Conduct brainstorming, designing and producing concepts</li>
                                    <li>Develop design ideas through coordination with merchandising and client.</li>
                                    <li>Ensure developing of patterns & overseeing the production</li>
                                    <li>Able to work in illustrator or any other fashion design software to develop Woven tops & Bottom sketches for men, ladies, kids accessories details.</li>
                                    <li>Must be able to develop new collection for every season.</li>
                                    <li>Making Flat Sketch (Tops & Bottoms) in graphic software.</li>
                                    <li>Design new labeling and badge.</li>
                                    <li>Making sketch according to Buyer request.</li>
                                    <li>Making presentation file for customer meeting.</li>
                                    <li>Design & develop men's and women's traditional product line up based on seasons that will generate sales in local and global market</li>
                                    <li>Study & analyze the market & industry trend to have a reflection on product design & development to fulfill the customer needs</li>
                                    <li>Present innovative saleable design ideas to the creative director, MD and sales team Determine product fabric & fitting (select fabrics, embellishments, color, styles which will fit for each garmentor accessory within the budget)</li>
                                    <li>Understanding design from technical perspective, developing pattern & supervision of these including fitting, detailing & adaptions </li>
                                    <li>Create a project book and check sample</li>
                                    <li>Employment Status</li>
                                    <li>Full-time</li>
                                </ul>
                                <h6 className="sub-title">Educational Requirements</h6>
                                <p>Bachelor of Fine Arts (B.F.A)</p>
                                <p>Training/Trade Course: Shanto Mariam University of Creative Technology,BGMEA University of Fashion</p>
                                <p>Technology students wi,Skills Required: Graphics Design; Local and world populated Fashion house (Buyer)</p>
                                <h6 className="sub-title">Experience Requirements</h6>
                                <p>1 to 3 year(s)</p>
                                <p>The applicants should have experience in the following area(s):</p>
                                <p>Fashion Design</p>
                                <h6 className="sub-title">Additional Requirements</h6>
                                <p>Age 26 to 35 years</p>
                                <p>Both males and females are allowed to apply</p>
                                <h6 className="sub-title">Job Location</h6>
                                <p>Gulshan, Dhaka Division, Dhaka.</p>
                                <h6 className="sub-title">Salary</h6>
                                <p className="m-0">Negotiable</p>
                                <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                            </div>
                            <div className="col-xl-3 col-lg-4 offset-xl-1">
                                <div className="widget widget-job-details">
                                    <h3 className="widget-title">Job Details</h3>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/1.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Department</h6>
                                            <span>UI Designer</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/2.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Location</h6>
                                            <span>Bangladesh</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/3.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Job Type</h6>
                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/4.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Experience</h6>
                                            <span>2 years</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details mb-0">
                                        <img src="assets/img/icons/5.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Salary</h6>
                                            <span>$2000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center" id="NestJS">
                            <div className="col-xl-6 col-lg-8 offset-xl-1">
                                <div className="section-title">
                                    <h2 className="title">Job Details</h2>
                                </div>
                                <h6 className="title">Fashion Designer</h6>
                                <span>Bevilard international</span>
                                <h6 className="sub-title">Vacancy</h6>
                                <span>04</span>
                                <h6 className="sub-title">Job Responsibilities</h6>
                                <ul>
                                    <li>Find out fashion trends of International market and to work out of them</li>
                                    <li>Creation of creative fashion design that suits the company brand's requirement.</li>
                                    <li>Development of new accessories design for upcoming collection.</li>
                                    <li>Analyze development in fabrics, colors and shapes</li>
                                    <li>Conduct brainstorming, designing and producing concepts</li>
                                    <li>Develop design ideas through coordination with merchandising and client.</li>
                                    <li>Ensure developing of patterns & overseeing the production</li>
                                    <li>Able to work in illustrator or any other fashion design software to develop Woven tops & Bottom sketches for men, ladies, kids accessories details.</li>
                                    <li>Must be able to develop new collection for every season.</li>
                                    <li>Making Flat Sketch (Tops & Bottoms) in graphic software.</li>
                                    <li>Design new labeling and badge.</li>
                                    <li>Making sketch according to Buyer request.</li>
                                    <li>Making presentation file for customer meeting.</li>
                                    <li>Design & develop men's and women's traditional product line up based on seasons that will generate sales in local and global market</li>
                                    <li>Study & analyze the market & industry trend to have a reflection on product design & development to fulfill the customer needs</li>
                                    <li>Present innovative saleable design ideas to the creative director, MD and sales team Determine product fabric & fitting (select fabrics, embellishments, color, styles which will fit for each garmentor accessory within the budget)</li>
                                    <li>Understanding design from technical perspective, developing pattern & supervision of these including fitting, detailing & adaptions </li>
                                    <li>Create a project book and check sample</li>
                                    <li>Employment Status</li>
                                    <li>Full-time</li>
                                </ul>
                                <h6 className="sub-title">Educational Requirements</h6>
                                <p>Bachelor of Fine Arts (B.F.A)</p>
                                <p>Training/Trade Course: Shanto Mariam University of Creative Technology,BGMEA University of Fashion</p>
                                <p>Technology students wi,Skills Required: Graphics Design; Local and world populated Fashion house (Buyer)</p>
                                <h6 className="sub-title">Experience Requirements</h6>
                                <p>1 to 3 year(s)</p>
                                <p>The applicants should have experience in the following area(s):</p>
                                <p>Fashion Design</p>
                                <h6 className="sub-title">Additional Requirements</h6>
                                <p>Age 26 to 35 years</p>
                                <p>Both males and females are allowed to apply</p>
                                <h6 className="sub-title">Job Location</h6>
                                <p>Gulshan, Dhaka Division, Dhaka.</p>
                                <h6 className="sub-title">Salary</h6>
                                <p className="m-0">Negotiable</p>
                                <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                            </div>
                            <div className="col-xl-3 col-lg-4 offset-xl-1">
                                <div className="widget widget-job-details">
                                    <h3 className="widget-title">Job Details</h3>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/1.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Department</h6>
                                            <span>UI Designer</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/2.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Location</h6>
                                            <span>Bangladesh</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/3.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Job Type</h6>
                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/4.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Experience</h6>
                                            <span>2 years</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details mb-0">
                                        <img src="assets/img/icons/5.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Salary</h6>
                                            <span>$2000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center" id="UI">
                            <div className="col-xl-6 col-lg-8 offset-xl-1">
                                <div className="section-title">
                                    <h2 className="title">Job Details</h2>
                                </div>
                                <h6 className="title">Fashion Designer</h6>
                                <span>Bevilard international</span>
                                <h6 className="sub-title">Vacancy</h6>
                                <span>04</span>
                                <h6 className="sub-title">Job Responsibilities</h6>
                                <ul>
                                    <li>Find out fashion trends of International market and to work out of them</li>
                                    <li>Creation of creative fashion design that suits the company brand's requirement.</li>
                                    <li>Development of new accessories design for upcoming collection.</li>
                                    <li>Analyze development in fabrics, colors and shapes</li>
                                    <li>Conduct brainstorming, designing and producing concepts</li>
                                    <li>Develop design ideas through coordination with merchandising and client.</li>
                                    <li>Ensure developing of patterns & overseeing the production</li>
                                    <li>Able to work in illustrator or any other fashion design software to develop Woven tops & Bottom sketches for men, ladies, kids accessories details.</li>
                                    <li>Must be able to develop new collection for every season.</li>
                                    <li>Making Flat Sketch (Tops & Bottoms) in graphic software.</li>
                                    <li>Design new labeling and badge.</li>
                                    <li>Making sketch according to Buyer request.</li>
                                    <li>Making presentation file for customer meeting.</li>
                                    <li>Design & develop men's and women's traditional product line up based on seasons that will generate sales in local and global market</li>
                                    <li>Study & analyze the market & industry trend to have a reflection on product design & development to fulfill the customer needs</li>
                                    <li>Present innovative saleable design ideas to the creative director, MD and sales team Determine product fabric & fitting (select fabrics, embellishments, color, styles which will fit for each garmentor accessory within the budget)</li>
                                    <li>Understanding design from technical perspective, developing pattern & supervision of these including fitting, detailing & adaptions </li>
                                    <li>Create a project book and check sample</li>
                                    <li>Employment Status</li>
                                    <li>Full-time</li>
                                </ul>
                                <h6 className="sub-title">Educational Requirements</h6>
                                <p>Bachelor of Fine Arts (B.F.A)</p>
                                <p>Training/Trade Course: Shanto Mariam University of Creative Technology,BGMEA University of Fashion</p>
                                <p>Technology students wi,Skills Required: Graphics Design; Local and world populated Fashion house (Buyer)</p>
                                <h6 className="sub-title">Experience Requirements</h6>
                                <p>1 to 3 year(s)</p>
                                <p>The applicants should have experience in the following area(s):</p>
                                <p>Fashion Design</p>
                                <h6 className="sub-title">Additional Requirements</h6>
                                <p>Age 26 to 35 years</p>
                                <p>Both males and females are allowed to apply</p>
                                <h6 className="sub-title">Job Location</h6>
                                <p>Gulshan, Dhaka Division, Dhaka.</p>
                                <h6 className="sub-title">Salary</h6>
                                <p className="m-0">Negotiable</p>
                                <Link to="/jobApply" className="job-apply-btn">Apply Now</Link>
                            </div>
                            <div className="col-xl-3 col-lg-4 offset-xl-1">
                                <div className="widget widget-job-details">
                                    <h3 className="widget-title">Job Details</h3>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/1.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Department</h6>
                                            <span>UI Designer</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/2.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Location</h6>
                                            <span>Bangladesh</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/3.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Job Type</h6>
                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details">
                                        <img src="assets/img/icons/4.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Experience</h6>
                                            <span>2 years</span>
                                        </div>
                                    </div>
                                    <div className="media single-job-details mb-0">
                                        <img src="assets/img/icons/5.svg" alt="icon" />
                                        <div className="media-body">
                                            <h6>Salary</h6>
                                            <span>$2000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {selectedDiv ? (
                            <div id={selectedDiv.id}>
                                <p>{selectedDiv.content}</p>
                            </div>
                        ) : (
                            <p>Div not found!</p>
                        )}
                    </div>
                </div>

                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up"></i></span>
                </div>


            </div>
        </>
    )
}

export default JobDetails;