import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <>
            <div>

                {/* <div class="preloader" id="preloader">
                    <div class="preloader-inner">
                        <div class="spinner">
                            <div class="dot1"></div>
                            <div class="dot2"></div>
                        </div>
                    </div>
                </div> */}

                <div className="header-area header-bg" style={{ "background-image": "url(img/bg/1.png)", "background-repeat": "no-repeat", "background-size": "cover" }}>
                    <div className="container">
                        <div className="banner-slider-one">
                            <div className="banner-slider-item" data-interval="false">
                                <div className="row">
                                    <div className="col-md-12 offset-xl-1" style={{"text-align":"center"}}>
                                        <div className="header-inner-details">
                                            <div className="header-inner">
                                                <h1 className="title s-animate-1" style={{ color: "#0f172a", "font-size": "30px" }}>Revolutionizing Construction Through <span style={{ color: "#2563eb" }}>AI</span> : Streamlining <span style={{ color: "#2563eb" }}>Procurement</span> Processes</h1>
                                                <p className="s-animate-2" style={{ "text-align": "center" }}>Our procurement system significantly reduces costs, boosts ROI, enhances efficiency, and supports Construction Companies in completing projects within planned budgets. Furthermore, advanced procurement tools and features streamline issues such as material shortages, price fluctuations, and communication and information flow challenges.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 hidden-sm">
                                        <div className="banner-thumb-wrap">
                                            <div className="banner-thumb">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
                        <img className="rounded-corners" src="img/Dashboard/AppPrototype.svg" alt="service" />
                    </div>
                </div>

                <div id="modules"></div>

                <div style={{ "margin-top": "111px" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-10">
                                <div className="section-title text-center margin-bottom-90">
                                    <h2 className="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s" style={{ color: "#0f172a" }}>Our <span style={{ color: "#2563eb" }}>Key</span> Modules</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row custom-gutters-16" style={{"text-align":"center"}}>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
                                    <img className="center" src="img/Dashboard/wrkschedule.svg" alt="service" />
                                    <h6><a href="#">Work Schedule Management</a></h6>
                                    <p>Streamlines Work schedules with real-time monitoring, automated alerts, and efficient resource allocation, ensuring on-time completion and operational efficiency in construction projects.</p>
                                    <div className="read-more">
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">
                                    <img className="center" src="img/Dashboard/constrtmgmt.svg" alt="service" />
                                    <h6><a href="#">Construction Management</a></h6>
                                    <p>Optimize project planning, scheduling, resource and material management for efficient construction delivery and cost control.<br /><br /></p>
                                    <div className="read-more">

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                    <img className="center" src="img/Dashboard/procurementMarkt.svg" alt="service" />
                                    <h6><a href="#">Procurement Marketplace</a></h6>
                                    <p>Streamlines construction procurement by connecting builders with a network of suppliers, enabling competitive bidding, efficient purchasing, and real-time order tracking.</p>
                                    <div className="read-more">

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="single-service wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                                    <img className="center" src="img/Dashboard/Procuretopay.svg" alt="service" />
                                    <h6><a href="#">Procure to pay</a></h6>
                                    <p> Monitors and manages procurement workflows, from order placement to delivery, ensuring efficient supplier interactions and timely material availability for projects.</p>
                                    <div className="btn-wrapper" style={{ "margin-bottom": "-14px", "text-align": "right" }}>
                                        <Link to="procureToPay" className="btn btn-radius btn-info rounded-full s-animate-3"
                                            style={{ "text-align": "right", "background-color": "#2563eb", "border-color": "#2563eb", "border-radius": "35px", "font-size": "10px", width: "94", height: "28px" }}>
                                            <p style={{ color: "white", "font-weight": "bold", "font-size": "12px", "margin-top": "-2px" }}>Read more</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sbs-talented-people mg-top-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 offset-xl-1 desktop-center-item">
                                <div className="desktop-center-area">
                                    <div className="section-title style-three">
                                        <h2 className="title" style={{ color: "#0f172a" }}>30+ Talented People</h2>
                                    </div>
                                    <div className="talented-pepole-list">
                                        <ul>
                                        <li>
                                                <h3 className="count-num">20</h3>
                                                <span>Developer</span>
                                            </li>
                                            <li>
                                                <h3 className="count-num">2</h3>
                                                <span>Architect</span>
                                            </li>
                                            <li>
                                                <h3 className="count-num">5</h3>
                                                <span>Project Manager</span>
                                            </li>
                                            <li>
                                                <h3 className="count-num">4</h3>
                                                <span>UI/UX Designer</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="world-map-wrap">
                                    <img src="img/others/meragaomap.png" alt="map" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="leftside-image-area  sbs-business-tool pd-bottom-120 mg-top-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-xl-7 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
                                <img src="img/business-tool/1.png" alt="img" />
                            </div>
                            <div className="col-xl-5 col-lg-6 desktop-center-item">
                                <div className="desktop-center-area wow animated fadeInLeft" data-wow-duration="1s"
                                    data-wow-delay="0.3s">
                                    <div className="section-title style-two">
                                        <h2 className="title" style={{ color: "#0f172a" }}>Connect Your <span>tools</span> <br /><span>Automate</span> Your Workflows.</h2>
                                        <p>ProcureX provides a RESTful API connection, enabling developers to swiftly integrate it with any other system. Our APIs facilitate a seamless two-way synchronization of data between ProcureX and other systems.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-9">
                                            <div className="riyaqas-check-list media">
                                                <img className="media-left" src="img/Dashboard/solutions.png" width="40px" alt="check" />
                                                <span><b style={{ color: "#0f172a" }}>Native Integrations:</b> ProcureX can seamlessly integrate with today’s biggest ERP/accounting systems.</span>
                                            </div>
                                            <div className="riyaqas-check-list media">
                                                <img className="media-left" src="img/Dashboard/api.png" width="40px" alt="check" />
                                                <span><b style={{ color: "#0f172a" }}>API Connection:</b> ProcureX offers RESTful APIs that can be used to develop an integration quickly to push or pull data.</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="newsletter-area mg-top-50">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-10 text-center">
                                <div className="section-title">
                                    <h2 className="title" style={{ color: "#0f172a" }}>Subscribe</h2>
                                    <p>Offer your business with the best assistance for growth.</p>
                                </div>
                                <div className="newsletter-subcribe">
                                    <form id="news-subcribeform" className="subcribe-form">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Your E-mail..." name="mail"
                                                required="" />
                                            <button type="submit" className="subcribe-submit"
                                                style={{ "background-color": "#2563eb" }}>submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up"></i></span>
                </div>
            </div>
        </>
    )
}

export default Home;